import { NotificationsStore } from './../states/notifications/notifications.store.js';
import { Injectable } from '@angular/core';
import { NotificationsApi } from '../apis/index.js';
import { CurrentUserFacade } from '@awread/core/users';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SnackbarService } from '@awread/global/packages';
import { DepositsFacade } from '@awread/core/deposit';
import { LuckyMoneyNotificationsStore } from '../states/lucky-money-notifications/index.js';
import { environment } from '@awread/global/environments';

@Injectable({ providedIn: 'root' })
export class NotificationsGear {
  constructor(
    private notificationsApi: NotificationsApi,
    private currentUserFacade: CurrentUserFacade,
    private notificationsStore: NotificationsStore,
    private LuckyMoneyNotificationsStore: LuckyMoneyNotificationsStore,
    private snackbarService: SnackbarService,
    private depositsFacade: DepositsFacade
  ) { }

  getNotiDepositCompleted() {
    return this.notificationsApi.getNotiDepositCompleted().pipe(
      tap((payload) => {
        if (payload.length && this.currentUserFacade.currentUserQuery.getValue().haveDialogNoti) {
          this.snackbarService
            .showLiveNotification(`Bạn đã nạp thành công ${Math.floor(+payload[0].data.amount).toLocaleString()} Coin vào Awread`)
            .afterClosed$.subscribe(() => {
              this.currentUserFacade.updateHaveDialogNoti(false).subscribe();
              this.currentUserFacade.updateHaveNoti(false).subscribe();
              this.notificationsApi.updateNotification(payload[0].notificationId).subscribe();
            });
        } else {
          console.log('not show deposit noti', payload, this.currentUserFacade.currentUserQuery.getValue().haveDialogNoti)
        }
      })
    );
  }

  startWatchingDepositSuccessNotifications() {
    // MUST ACTIVE IT ON PRODUCTION
    // if (!environment.production) {
    //   return of();
    // }
    return this.currentUserFacade.onUserIdChanges().pipe(
      switchMap((userId) => {
        // console.log('userId', userId);
        if (userId) {
          return this.notificationsApi.watchNotifications(userId);
        } else {
          return of();
        }
      }),
      tap((payload) => {
        console.log('notifications data', payload);
        switch (payload.type) {
          case 'deposit:completed':
            this.snackbarService
              .showLiveNotification(`Bạn đã nạp thành công ${Math.floor(+payload.data.amount).toLocaleString()} Coin vào Awread`)
              .afterClosed$.subscribe(() => {
                this.currentUserFacade.updateHaveDialogNoti(false).subscribe();
                this.currentUserFacade.updateHaveNoti(false).subscribe();
                this.notificationsApi.updateNotification(payload.notificationId).subscribe();
              });
            this.depositsFacade.getUserWallet().subscribe();
            break;

          default:
            break;
        }
      })
    );
  }

  getAllNotifications() {
    return this.notificationsApi.getAllNotifications(this.currentUserFacade.getUserId()).pipe(tap((res) => this.notificationsStore.set(res)));
  }

  createNotification(userId: any, bookId: any, type: any, data?: any) {
    return this.notificationsApi
      .createNotification(userId, bookId, type, this.currentUserFacade.getUserId(), data)
      .pipe(tap((res) => this.notificationsStore.add(res)));
  }

  updateNotification(notificationId: any) {
    return this.notificationsApi.updateNotification(notificationId).pipe(tap((res) => this.notificationsStore.update(res)));
  }

  createNotificationPaying(userId: any, type: any, data: any) {
    return this.notificationsApi.createNotificationPaying(userId, type, data).pipe(tap((res) => this.notificationsStore.add(res)));
  }

  getAllNotiLuckyMoney() {
    return this.notificationsApi.getAllNotiLuckyMoney().pipe(tap((res) => this.LuckyMoneyNotificationsStore.set(res)));
  }
}
