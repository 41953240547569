import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { NotificationsStore, NotificationsState } from './notifications.store.js';
// import { NotificationsStore, NotificationsState, NotificationsUIState } from './notifications.store';

@Injectable({ providedIn: 'root' })
export class NotificationsQuery extends QueryEntity<NotificationsState> {
  // ui!: EntityUIQuery<NotificationsUIState>;
  constructor(protected override store: NotificationsStore) {
    super(store);
    // this.createUIQuery();
  }
}
