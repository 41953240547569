import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { Notification } from '../../models/index.js';

export enum VISIBILITY_FILTER {
  SHOW_ALL = 'SHOW_ALL',
}

export interface NotificationsState extends EntityState<Notification>, ActiveState {
  ui: {
    filter: VISIBILITY_FILTER;
  };
}
const initialState = {
  ui: {
    filter: VISIBILITY_FILTER.SHOW_ALL,
  },
};

// export interface NotificationUI {}

// export interface NotificationsUIState extends EntityState<NotificationUI>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'notifications', resettable: true, idKey: 'notificationId' })
export class NotificationsStore extends EntityStore<NotificationsState> {
  // ui: EntityUIStore<NotificationsUIState>;
  constructor() {
    super();
    // this.createUIStore().setInitialEntityState();
  }
}
