import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { LuckyMoneyNotification } from '../../models/index.js';

export enum VISIBILITY_FILTER {
  SHOW_ALL = 'SHOW_ALL',
}

export interface LuckyMoneyNotificationsState extends EntityState<LuckyMoneyNotification>, ActiveState {
  ui: {
    filter: VISIBILITY_FILTER;
  };
}
const initialState = {
  ui: {
    filter: VISIBILITY_FILTER.SHOW_ALL,
  },
};

// export interface LuckyMoneyNotificationsUI {}

// export interface LuckyMoneyNotificationsUIState extends EntityState<LuckyMoneyNotificationsUI>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lucky-money-notifications', resettable: true, idKey: 'notificationId' })
export class LuckyMoneyNotificationsStore extends EntityStore<LuckyMoneyNotificationsState> {
  // ui: EntityUIStore<LuckyMoneyNotificationsUIState>;
  constructor() {
    super();
    // this.createUIStore().setInitialEntityState();
  }
}
