import { Injectable } from '@angular/core';
import { CurrentUserFacade } from '@awread/core/users';
import { Apollo, gql } from 'apollo-angular';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NotificationsApi {
  constructor(private apollo: Apollo, private currentUserFacade: CurrentUserFacade) {}

  watchNotifications(userId: string) {
    return this.apollo
      .subscribe<any>({
        query: gql`
          subscription listenToNotifications($topic: String!) {
            listen(topic: $topic) {
              relatedNodeId
              relatedNode {
                nodeId
                ... on Notification {
                  bookId
                  type
                  createdAt
                  data
                  nodeId
                  notificationId
                  seen
                  userId
                  senderId
                }
              }
            }
          }
        `,
        variables: { topic: `notifications:${userId}` },
      })
      .pipe(map((res) => res?.['data']?.['listen']?.['relatedNode']));
  }

  getNotiDepositCompleted() {
    return this.apollo
      .query<any>({
        query: gql`
          query notiDepositCompleted($userId: UUID!) {
            notifications(condition: { userId: $userId, type: "deposit:completed", seen: false }, orderBy: CREATED_AT_DESC) {
              nodes {
                notificationId
                bookId
                createdAt
                nodeId
                data
                seen
                senderId
                userId
                type
              }
            }
          }
        `,
        variables: { userId: this.currentUserFacade.getUserId() },
        fetchPolicy: 'network-only',
      })
      .pipe(map((res) => res?.['data']?.['notifications']?.['nodes']));
  }

  getAllNotifications(userId: any, first = 10) {
    return this.apollo
      .query<any>({
        query: gql`
          query allNotifications($userId: UUID!, $first: Int) {
            notifications(
              first: $first
              filter: { createdAt: { isNull: false } }
              condition: { userId: $userId, action: null, group: null }
              orderBy: CREATED_AT_DESC
            ) {
              nodes {
                notificationId
                bookId
                createdAt
                nodeId
                data
                seen
                senderId
                userId
                type
                book {
                  title
                  bookNid
                }
                sender {
                  avatar
                  name
                  userId
                  username
                }
              }
            }
          }
        `,
        variables: { userId, first },
      })
      .pipe(
        map((res) => res?.['data']?.['notifications']?.['nodes']),
        map((res) =>
          res.map((noti: any) => ({
            ...noti,
            coin: Math.floor(Number(noti.data)).toLocaleString('it-IT'),
          }))
        )
      );
  }

  createNotification(userId: any, bookId: any, type: any, senderId: any, data?: any) {
    const currentTime = new Date();
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation createNoti($userId: UUID!, $senderId: UUID!, $type: String, $bookId: UUID!, $createdAt: Datetime, $data: JSON) {
            createNotification(
              input: { notification: { userId: $userId, senderId: $senderId, type: $type, seen: false, bookId: $bookId, createdAt: $createdAt, data: $data } }
            ) {
              notification {
                bookId
                createdAt
                data
                nodeId
                notificationId
                seen
                senderId
                type
                userId
              }
            }
          }
        `,
        variables: { userId, bookId, type, senderId, createdAt: currentTime, data },
      })
      .pipe(map((res) => res?.['data']?.['createNotification']?.['notification']));
  }

  updateNotification(notificationId: any) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation updateNotification($notificationId: UUID!) {
            updateNotification(input: { patch: { seen: true }, notificationId: $notificationId }) {
              notification {
                seen
              }
            }
          }
        `,
        variables: { notificationId },
      })
      .pipe(map((res) => res?.['data']?.['updateNotification']?.['notification']));
  }

  createNotificationPaying(userId: any, type: any, data: any) {
    const currentTime = new Date();
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation createNotiPaying($userId: UUID!, $type: String, $data: JSON, $createdAt: Datetime) {
            createNotification(input: { notification: { userId: $userId, type: $type, data: $data, createdAt: $createdAt, seen: false } }) {
              notification {
                bookId
                createdAt
                data
                nodeId
                notificationId
                seen
                senderId
                type
                userId
              }
            }
          }
        `,
        variables: { userId, type, data, createdAt: currentTime },
      })
      .pipe(map((res) => res?.['data']?.['createNotification']?.['notification']));
  }

  getAllNotiLuckyMoney() {
    return this.apollo
      .query<any>({
        query: gql`
          query allNotiLuckyMoney($userId: UUID!) {
            notifications(orderBy: CREATED_AT_ASC, condition: { userId: $userId, action: "dialog", group: "system", seen: false }) {
              nodes {
                action
                bookId
                data
                group
                seen
                userId
                type
                createdAt
                notificationId
              }
            }
          }
        `,
        variables: { userId: this.currentUserFacade.getUserId() },
        fetchPolicy: 'network-only',
      })
      .pipe(map((res) => res?.['data']?.['notifications']?.['nodes']));
  }
}
