import { NotificationsQuery } from './../states/notifications/notifications.query.js';
import { NotificationsGear } from './../gears/notifications.gear.js';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { LuckyMoneyNotificationsQuery } from '../states/lucky-money-notifications/index.js';
@Injectable({ providedIn: 'root' })
export class NotificationsFacade {
  constructor(
    private notificationsGear: NotificationsGear,
    public notificationsQuery: NotificationsQuery,
    public luckyMoneyNotificationsQuery: LuckyMoneyNotificationsQuery
  ) {}

  notifications$ = this.notificationsQuery.selectAll();
  luckyMoneyNotis$ = this.luckyMoneyNotificationsQuery.selectAll();

  getAllNotifications() {
    return this.notificationsGear.getAllNotifications();
  }

  getNotiDepositCompleted() {
    return this.notificationsGear.getNotiDepositCompleted();
  }

  createNotification(userId: any, bookId: any, type: any, data?: any) {
    return this.notificationsGear.createNotification(userId, bookId, type, data);
  }

  updateNotification(notificationId: any) {
    return this.notificationsGear.updateNotification(notificationId);
  }

  createNotificationPaying(userId: any, type: any, data: any) {
    return this.notificationsGear.createNotificationPaying(userId, type, data);
  }

  startWatchingDepositSuccessNotifications() {
    return this.notificationsGear.startWatchingDepositSuccessNotifications();
  }

  getAllNotiLuckyMoney() {
    return this.notificationsGear.getAllNotiLuckyMoney();
  }
}
