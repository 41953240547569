import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { LuckyMoneyNotificationsStore, LuckyMoneyNotificationsState } from './lucky-money-notifications.store.js';
// import { LuckyMoneyNotificationsStore, LuckyMoneyNotificationsState, LuckyMoneyNotificationsUIState } from './notifications-lucky-money.store';

@Injectable({ providedIn: 'root' })
export class LuckyMoneyNotificationsQuery extends QueryEntity<LuckyMoneyNotificationsState> {
  // ui!: EntityUIQuery<LuckyMoneyNotificationsUIState>;
  constructor(protected override store: LuckyMoneyNotificationsStore) {
    super(store);
    // this.createUIQuery();
  }
}
